import React, { useEffect } from "react";
import "./App.less";

import Routes from "./routes";

const App = () => {
  useEffect(() => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log("cache clean");
  }, []);

  return <Routes />;
};

export default App;
