export const StepFooterStyle = {
  alignItems: "center",
  justifyItems: "center",
  position: "fixed",
  left: "0px",
  right: "0px",
  bottom: "0px",
  paddingBottom: "0.5rem",
  backgroundColor: "#F3F4F7",
  display: "flex",
  flexDirection: "column",
  WebkitBoxPack: "center",
  justifyContent: "center",
  zIndex: 3000,
};