
import moment from "moment";
import "moment/locale/pt-br";

export function validatePhone(phone) {
    var regex = new RegExp("^\\(((1[1-9])|([2-9][0-9]))\\) 9[0-9]{4}-[0-9]{4}$");
    return regex.test(phone);
  }

export const isNotExpired = (itemDate) => {
    const currentDay = moment();
    if (currentDay.isAfter(itemDate)) return false;
    return true;
  };