import React from 'react'
import { Button, Result } from 'antd'
import { useHistory } from 'react-router-dom'

const Page404 = () => {
  const history = useHistory()
  return (
  <Result
    status='404'
    title='404'
    subTitle='Desculpa, esta página não existe!'
    extra={<Button type='primary' onClick={() =>{ history.push('/')}}>Ir para o início</Button>}
  />
)}

export default Page404
