import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import PublicLayout from "./layouts/PublicLayout";

import Chat from "./pages/Chat";
import Page404 from "./pages/404";
import PageSuccess from "./pages/Success";
import AppointmentCancel from "./pages/AppointmentCancel";

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <PublicLayout>
        <Component {...props} />
      </PublicLayout>
    )}
  />
);

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={() => {
            // FIXME Create a portal services of appointments
            window.location.href = 'https://marqueai.com.br';
            return null;
        }}/>
       
        <Route exact path="/404" component={Page404} />
        <Route exact path="/success" component={PageSuccess} />

        <PublicRoute exact path="/:username" component={Chat} />
        <PublicRoute
          exact
          path="/agendamento/:hash/cancelar"
          component={AppointmentCancel}
        />

        <Redirect
          to={{
            pathname: "/",
          }}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
