import { useMemo } from "react";
import { Button, Checkbox } from "antd";

import {
  minutesToHourString,
  priceToCurrencyString,
} from "../../../utils/format";
import { StepFooterStyle } from "../styles/stepFooterStyle";

const ServicesStep = ({
  services,
  servicesSelected,
  onSelectService,
  onFinish,
}) => {
  const servicesTotalTime = useMemo(
    () =>
      services
        .filter((item) => servicesSelected.includes(item.id))
        .reduce((accumulator, item) => accumulator + item.duration, 0),
    [services, servicesSelected]
  );

  return (
    <>
      <div className="select-checkbox">
        {services.map((item) => (
          <div
            className={`checkbox-service ${
              servicesSelected.includes(item.id) ? "checkbox-item--active" : ""
            }`}
            key={item.id}
          >
            <Checkbox
              onClick={() => onSelectService(item.id)}
              checked={servicesSelected.includes(item.id)}
            >
              <div className="contanier">
                <div className="details">
                  <p className="name">
                    <b>{item.name}</b>
                  </p>
                  <p className="time">
                    Tempo: {minutesToHourString(item.duration)}{" "}
                  </p>
                </div>
              </div>
            </Checkbox>
            <div className="value">
              <p>{priceToCurrencyString(item.price)}</p>
            </div>
          </div>
        ))}
      </div>
      <div style={StepFooterStyle}>
        <Button
          type="primary"
          htmlType="submit"
          className="action"
          size="large"
          disabled={servicesTotalTime === 0}
          onClick={onFinish}
        >
          Selecionar serviços
        </Button>
      </div>
    </>
  );
};

export default ServicesStep;
