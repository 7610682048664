import { useEffect, useState } from "react";
import { Button, Select, Calendar, Row, Col } from "antd";

import moment from "moment";
import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_PT";

import { InputStyle } from "../styles/inputStyle";
import { StepFooterStyle } from "../styles/stepFooterStyle";
import api from "../../../services/api";
import { weekString } from "../../../utils/constants";

const DateStep = ({
  date,
  onChangeDate,
  servicesTotalTime,
  professional,
  onFinish,
}) => {
  const [currentMonth, setCurrentMonth] = useState(null);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [daysOfMonthUnavailable, setDaysOfMonthUnavailable] = useState([]);
  const [daysAvailable, setDaysAvailable] = useState([]);

  const getDaysOfMonthUnavailables = () => {
    setLoadingCalendar(true);
    api
      .post(`/schedules/unavailable/month`, {
        user_id: professional.id,
        month: date.format("YYYY-MM"),
        total_duration: servicesTotalTime,
      })
      .then((response) => {
        setDaysOfMonthUnavailable(response.data);
        setLoadingCalendar(false);
      });
  };

  useEffect(() => {
    api
      .get(`/user/${professional.username}/days/available`)
      .then((response) => {
        setDaysAvailable(response.data.data.map((item) => item.day));
      });
  }, [professional.username]);

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    if (!current) return false;
    if (current < moment().startOf("day")) return true;
    if (!daysAvailable.includes(weekString[current.day()])) return true;
    if (daysOfMonthUnavailable.includes(current.format("YYYY-MM-DD")))
      return true;
    return false;
  };

  return (
    <>
      <div className="calendar">
        <Calendar
          fullscreen={false}
          onPanelChange={(value) => onChangeDate(value)}
          onChange={(value) => onChangeDate(value)}
          onSelect={(value) => onChangeDate(value)}
          disabledDate={disabledDate}
          locale={locale}
          headerRender={({ value, onChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];

            const current = value.clone();
            const localeData = value.localeData();
            const months = [];
            for (let i = 0; i < 12; i++) {
              current.month(i);
              months.push(localeData.months(current));
            }

            for (let i = start; i < end; i++) {
              monthOptions.push(
                <Select.Option key={i} value={i} className="month-item">
                  {months[i]}
                </Select.Option>
              );
            }

            const year = value.year();
            const month = value.month();
            const options = [];
            for (let i = year; i < year + 10; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>
              );
            }

            if (currentMonth !== month) {
              getDaysOfMonthUnavailables();
              setCurrentMonth(month);
            }

            return (
              <div style={{ padding: 8 }}>
                <Row gutter={[24, 24]} style={{ width: "100%", margin: "0px" }}>
                  <Col span={12}>
                    <Select
                      size="middle"
                      dropdownMatchSelectWidth={false}
                      className="my-year-select"
                      value={year}
                      onChange={(newYear) => {
                        const now = value.clone().year(newYear);
                        getDaysOfMonthUnavailables();
                        onChange(now);
                      }}
                      style={{ width: "100%" }}
                    >
                      {options}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <Select
                      size="middle"
                      dropdownMatchSelectWidth={false}
                      value={month}
                      onChange={(newMonth) => {
                        const now = value.clone().month(newMonth);
                        onChange(now);
                      }}
                      style={{ width: "100%" }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                </Row>
              </div>
            );
          }}
        />
      </div>
      <div style={StepFooterStyle}>
        <Button
          type="primary"
          htmlType="submit"
          className="action"
          size="large"
          style={{
            height: `48px`,
            fontSize: `16px`,
            fontWeight: `bold`,
            ...InputStyle,
          }}
          disabled={
            !date || daysOfMonthUnavailable.includes(date.format("YYYY-MM-DD"))
          }
          onClick={onFinish}
          loading={loadingCalendar}
        >
          Verificar
        </Button>
      </div>
    </>
  );
};

export default DateStep;
