import React from 'react'

import { Layout } from 'antd'

const PublicLayout = ({ children }) => {
  return (
    <Layout
      style={{
        backgroundColor: "#F3F4F7",
        height: '100vh',
      }}
    >
      {children}
    </Layout>
  )
}

export default PublicLayout
