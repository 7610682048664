import React, { useState } from 'react'
import { Button, Descriptions, Modal, Result, Spin } from 'antd'
import {
  dateExtend,
  minutesToHourFormated,
  priceToCurrencyString,
} from '../../utils/format'
import { useParams } from 'react-router-dom'
import api from '../../services/api'
import { useHistory } from 'react-router-dom'
import { statusLabel } from '../../utils/constants'
import moment from "moment";
import "moment/locale/pt-br";

import './index.less';

const AppointmentCancel = () => {
  const params = useParams()
  const history = useHistory()
  const [appointment, setAppointment] = useState(null)
  const [loading, setLoading] = useState(true)
  const [modalCancelOpen, setModalCancelOpen] = useState(false)
  const [canceled, setCanceled] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useState(()=>{
    api.get(`/appointments/hash/${params.hash}`).then(response => {
      setLoading(false)
      if(response.status === 204){
        history.push('/404')
      }
      if(response.status === 200){
        setAppointment(response.data)        
      }
    })
  },[])

  const handleCancelAppoitment = () => {
    setLoading(true)
    api.get(`/appointments/hash/${params.hash}/cancel`).then(response => {
      setLoading(false)
      setCanceled(true)
    }).catch(error => {
      setLoading(false)
      setError(true)
      setErrorMessage(error.response.data)
    })
  }

  if(error){
    return (
      <Result
       status='error'
       title="Não foi possível cancelar!"
       subTitle={errorMessage}
      />
    )
  }

  if(canceled || appointment?.status === 'canceled'){
    return (
      <Result
       status='success'
       title='Agendamento Cancelado!'
       extra={
         (
          <Button type='primary' onClick={() => history.push(`/${appointment.username}`)}>
            Novo Agendamento
          </Button>
         )
       }
      />
    )
  }

  if(loading || !appointment){
    return (
      <Spin
        style={{ display: 'flex', justifyContent: 'center', marginTop: '50vh' }}
      />
    )
  }

  return (
    <>
    <Result
      status='info'
      title='Cancelar Agendamento?'
      extra={
        <>
          <Descriptions
            style={{whiteSpace: 'discard', textOverflow: "initial"}}
            title={`Agendamento Nº${appointment.id}`}
            size='small'
            layout='vertical'
          >
            <Descriptions.Item label='' style={{ textAlign: 'left' }}>
              Nome: {appointment?.name}
              <br />
              Telefone/Celular: {appointment?.number}
              <br />
              Data: {dateExtend(moment(appointment?.date).format("YYYY-MM-DD"))}
              <br />
              Horário: {minutesToHourFormated(appointment?.start_time)}
              <br />
              Preço: {priceToCurrencyString(appointment?.price)}
              <br />
              Status: {statusLabel[appointment.status]}
              {/* Status: {appointment.status} */}
            </Descriptions.Item>
          </Descriptions>
          <Button type='primary' onClick={() => setModalCancelOpen(true)}>
            Cancelar agendamento
          </Button>
        </>
      }
      
    />
    <Modal
          title='Cancelar agendamento'
          centered
          visible={modalCancelOpen}
          onOk={() => setModalCancelOpen(false)}
          onCancel={() => setModalCancelOpen(false)}
          footer={[
            <Button
              key='back'
              onClick={() => setModalCancelOpen(false)}
            >
              Não
            </Button>,
            <Button
              key='submit'
              type='primary'
              loading={loading}
              onClick={handleCancelAppoitment}
            >
              Sim
            </Button>,
          ]}
        >
          <Descriptions size='small' layout='vertical'>
            <Descriptions.Item label='' style={{ textAlign: 'left' }}>
              Tem certeza que desejar cancelar o agendamento?
            </Descriptions.Item>
          </Descriptions>
        </Modal>
    </>
  )
}

export default AppointmentCancel
