import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  List,
  Spin,
  Image,
  Col,
  Row,
  Modal,
  Descriptions,
  Avatar,
} from "antd";
import { UserOutlined } from "@ant-design/icons";

import { useHistory, useParams } from "react-router-dom";

import moment from "moment";
import "moment/locale/pt-br";
import Logo from "../../assets/logo.png";

import api from "../../services/api";
import {
  dateExtend,
  minutesToHourFormated,
  numberFormated,
  priceToCurrencyString,
} from "../../utils/format";
import { setCustomerAppointment } from "../../utils/authentication";
import "./index.less";

import { getStorageNumber, setStorageNumber } from "../../services/storage";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PhoneStep from "./steps/phoneStep";
import NameStep from "./steps/nameStep";
import ChoiceActionStep from "./steps/choiceActionStep";
import ServicesStep from "./steps/servicesStep";
import DateStep from "./steps/dateStep";
import ScheduleStep from "./steps/scheduleStep";
import AppointmentsListStep from "./steps/AppointmentsListStep";

const botMessage = (msg) => ({
  message: msg,
  type: "BOT",
});

const customerMessage = (msg) => ({
  message: msg,
  type: "CUSTOMER",
});

const botMessageStyle = {
  justifyContent: "flex-start",
  display: "flex",
  fontWeight: 500,
  padding: "10px",
  color: "#424F60",
  background: "#FFFFFF",
  borderRadius: "16px",
  maxWidth: "75%",
};

const customerMessageStyle = {
  fontWeight: 500,
  padding: "10px",
  color: "#FFFFFF",
  background: "#8E35FF",
  borderRadius: "16px",
  maxWidth: "75%",
};

const initialMessages = [botMessage("Olá, seja bem-vindo ✌️")];

const initialData = {
  name: "",
  number: "",
  services: [],
  serviceNames: "",
  date: "",
  dateExtend: "",
  price: 0,
};

const StepEnum = {
  APPOINTMENT_LIST: "APPOINTMENT_LIST",
  CHOICE_ACTION: "CHOICE_ACTION",
  DATE: "DATE",
  NAME: "NAME",
  PHONE: "PHONE",
  SERVICES: "SERVICES",
  SCHEDULE: "SCHEDULE",
};

const ChatStepsConfig = {
  0: StepEnum.PHONE,
  1: StepEnum.NAME,
  2: StepEnum.CHOICE_ACTION,
  3: StepEnum.APPOINTMENT_LIST,
  4: StepEnum.SERVICES,
  5: StepEnum.DATE,
  6: StepEnum.SCHEDULE,
};

const Chat = () => {
  const history = useHistory();
  const params = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const numberParams = searchParams.get("number");
  const [number, setNumber] = useState(
    numberFormated(numberParams) || getStorageNumber()
  );

  const [messages, setMessages] = useState(initialMessages);
  const messagesEndRef = useRef(null);

  const [name, setName] = useState("");
  const [data, setData] = useState(initialData);
  const [appointments, setAppointments] = useState([]);
  const [services, setServices] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [servicesSelected, setServicesSelected] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [schedule, setSchedule] = useState();
  const [loading, setLoading] = useState(false);
  const [professional, setProfessional] = useState();
  const [professionalLoading, setProfessionalLoading] = useState(true);

  const [date, setDate] = useState(moment());

  const [settings, setSettings] = useState(null);

  const [modalAppointmentConfirmOpen, setModalAppointmentConfirmOpen] =
    useState(false);
  const [modalAppointmentErrorOpen, setModalAppointmentErrorOpen] =
    useState(false);

  const [firstClick, setFirstClick] = useState(false);

  useEffect(() => {
    api
      .get(`/users/${params.username}/info`)
      .then((response) => {
        if (response.data === "Recurso não encontrado") history.push("/404");
        setProfessionalLoading(false);
        const professionalData = response.data.data;
        setProfessional(professionalData);
        setMessages([
          ...messages,
          botMessage(
            `Vou te ajudar a marcar um horário com ${professionalData.name}`
          ),
          botMessage("Para iniciar, informe seu telefone"),
        ]);
      })
      .catch((response) => {
        console.error(response);
      })
      .catch((response) => {
        console.error(response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.username, history]);

  useEffect(() => {
    if (professional) {
      api.get(`/users/${params.username}/services`).then((response) => {
        if (!response.data.data) return
        setServices(response.data.data);
      });
    }
  }, [professional, params.username]);

  useEffect(() => {
    api.get(`/chat/setting/user/${params.username}`).then((response) => {
      setSettings(response.data);
    });
  }, [params.username]);

  useEffect(() => {
    firstClick &&
      messagesEndRef &&
      messagesEndRef.current &&
      setTimeout(
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" }),
        1000
      );
  }, [firstClick, currentStep, messagesEndRef, schedules]);

  const getNameByTel = async (tel, number) => {
    setLoading(true);
    api.get(`/appointments/customer/${tel}`).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setData({
          ...data,
          number,
          name: response.data,
        });
        setName(response.data);
      }
      setMessages([
        ...messages,
        customerMessage(number),
        botMessage("Agora, qual seu nome?"),
      ]);
      setCurrentStep(currentStep + 1);
    });
  };

  const addNumber = async (number) => {
    setData({
      ...data,
      number,
    });

    setFirstClick(true);

    await getNameByTel(number.replace(/[^0-9]/g, ""), number);
    setStorageNumber(number);
  };

  const addName = () => {
    setData({
      ...data,
      name,
    });
    setMessages([
      ...messages,
      customerMessage(name),
      botMessage(`Legal, ${name}!`),
      botMessage("O que deseja realizar?"),
    ]);
    setCurrentStep(currentStep + 1);
  };

  const servicesTotalTime = useMemo(
    () =>
      services
        .filter((item) => servicesSelected.includes(item.id))
        .reduce((accumulator, item) => accumulator + item.duration, 0),
    [services, servicesSelected]
  );

  const NewAppointment = () => {
    setMessages([
      ...messages,
      customerMessage("Novo Agendamento"),
      botMessage("Selecione um ou mais serviços para agendamento:"),
    ]);
    setCurrentStep(4);
  };

  const MyAppointments = () => {
    setLoading(true);
    api
      .get(`/appointments/user/${params.username}/customer/${number}`)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setAppointments(response.data.data);
          setMessages([
            ...messages,
            customerMessage("Ver agendamentos"),
            botMessage(
              response.data.data.length > 0
                ? "Segue a lista dos seus agendamentos:"
                : "Você não possui agendamentos 😕"
            ),
          ]);
        } else {
          setMessages([
            ...messages,
            customerMessage("Ver agendamentos"),
            botMessage("Você não possui agendamentos..."),
          ]);
        }
        setCurrentStep(3);
      });
  };

  const getSchedules = () => {
    api
      .post(`/schedules/available`, {
        user_id: professional.id,
        date: date.format("YYYY-MM-DD"),
        total_duration: servicesTotalTime || 30,
      })
      .then((response) => {
        setLoading(false);
        if (typeof response.data === "string" || !response.data.length) {
          setMessages([
            ...messages,
            customerMessage(dateExtend(date.format("YYYY-MM-DD")) || ""),
            botMessage(
              `oops :(, Não temos horários disponíveis para esse dia, por favor, selecione outra data.`
            ),
          ]);
          setCurrentStep(5);
        } else {
          setMessages([
            ...messages,
            customerMessage(dateExtend(date.format("YYYY-MM-DD")) || ""),
            botMessage("Qual horário você deseja marcar?"),
          ]);
          setSchedules(response.data);
        }
      });
  };

  const addService = () => {
    const servicesTotalPrice = services
      .filter((item) => servicesSelected.includes(item.id))
      .reduce((accumulator, item) => {
        if (accumulator === "valor a combinar") {
          return accumulator;
        }
        if (item.price === 0) {
          return "valor a combinar";
        }
        return accumulator + item.price;
      }, 0);

    const serviceNames = services
      .filter((item) => servicesSelected.includes(item.id))
      .map((item) => item.name)
      .join(", ");

    const servicesMapped = services.filter((item) =>
      servicesSelected.includes(item.id)
    );

    setData({
      ...data,
      services: servicesSelected,
      price: servicesTotalPrice === "valor a combinar" ? 0 : servicesTotalPrice,
      serviceNames,
      servicesMapped,
    });

    setMessages([
      ...messages,
      customerMessage(serviceNames),
      botMessage("Qual dia você deseja agendar?"),
    ]);
    setCurrentStep(currentStep + 1);
  };

  const handleSelectService = (serviceId) => {
    const active = servicesSelected.includes(serviceId);
    if (active) {
      setServicesSelected(
        servicesSelected.filter((item) => item !== serviceId)
      );
    } else {
      setServicesSelected([...servicesSelected, serviceId]);
    }
  };

  const backToDateChange = () => {
    setMessages([
      ...messages,
      customerMessage("Voltar"),
      botMessage("Qual dia você deseja agendar?"),
    ]);
    setDate(moment());
    setCurrentStep(currentStep - 1);
  };

  const addDate = () => {
    setLoading(true);

    setData({
      ...data,
      date: date.format("YYYY-MM-DD"),
      dateExtend: dateExtend(date.format("YYYY-MM-DD")) || "",
    });

    setMessages([
      ...messages,
      customerMessage(dateExtend(date.format("YYYY-MM-DD")) || ""),
    ]);
    setCurrentStep(currentStep + 1);
    setTimeout(getSchedules, 1000);
  };

  const handleCreateAppoitment = () => {
    setLoading(true);
    api
      .post(`/appointments`, {
        ...data,
        user_id: professional.id,
        type: "service",
        start_time: schedule,
        end_time: Number(schedule) + servicesTotalTime,
        status: "pending",
      })
      .then((response) => {
        setLoading(false);
        setCustomerAppointment({ ...response.data, ...data, schedule });
        history.push("/success");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setCurrentStep(5);
        setModalAppointmentConfirmOpen(false);
        setModalAppointmentErrorOpen(true);
        setDate(moment());
        setMessages([
          ...messages,
          customerMessage(minutesToHourFormated(schedule)),
          botMessage("Qual dia você deseja agendar?"),
        ]);
      });
  };

  if (professionalLoading)
    return (
      <Spin
        style={{ display: "flex", justifyContent: "center", marginTop: "50vh" }}
      />
    );

  const StepComponents = {
    [StepEnum.PHONE]: (
      <PhoneStep
        onFinish={addNumber}
        number={number}
        onChangeNumber={setNumber}
      />
    ),
    [StepEnum.NAME]: (
      <NameStep name={name} onChangeName={setName} onFinish={addName} />
    ),
    [StepEnum.CHOICE_ACTION]: (
      <ChoiceActionStep
        onClickMyAppointments={MyAppointments}
        onClickNewAppointment={NewAppointment}
      />
    ),
    [StepEnum.APPOINTMENT_LIST]: (
      <AppointmentsListStep
        appointments={appointments}
        onClickNewAppointment={NewAppointment}
      />
    ),
    [StepEnum.SERVICES]: (
      <ServicesStep
        services={services}
        servicesSelected={servicesSelected}
        onSelectService={handleSelectService}
        onFinish={addService}
      />
    ),
    [StepEnum.DATE]: (
      <DateStep
        date={date}
        onChangeDate={setDate}
        onFinish={addDate}
        professional={professional}
        servicesTotalTime={servicesTotalTime}
      />
    ),
    [StepEnum.SCHEDULE]: (
      <ScheduleStep
        schedules={schedules}
        schedule={schedule}
        onChangeSchedule={setSchedule}
        OnClickBack={backToDateChange}
        onFinish={() => setModalAppointmentConfirmOpen(true)}
      />
    ),
  };

  return (
    <Row justify="center">
      <Col
        xs={{ span: 20 }}
        lg={{ span: 8 }}
        style={{
          maxWidth: "100%",
          minWidth: "100%",
          backgroundColor: "#F3F4F7",
        }}
      >
        <div
          style={{
            // paddingBottom: '10px',
            display: "flex",
            height: "10vh",
            alignItems: "center",
            padding: "10px",
            backgroundColor: "#FFFFFF",
            position: "fixed",
            left: "0px",
            right: "0px",
            top: "0px",
            zIndex: 3000,
          }}
        >
          <div style={{ width: "80vw", display: "flex", alignItems: "center" }}>
            {settings && settings.logo ? (
              <Avatar
                size={46}
                src={<img src={settings.logo} alt="avatar" />}
              />
            ) : (
              <Avatar size={46} icon={<UserOutlined />} />
            )}
            <div
              style={{
                fontWeight: "700",
                marginLeft: "10px",
              }}
            >
              {professional && professional.name}
            </div>
          </div>
          <Image src={Logo} width="140px" preview={false} />
        </div>
        <div style={{ marginTop: "80px", overflowY: "auto" }}>
          <List
            size="large"
            dataSource={messages}
            renderItem={(item) => (
              <div
                style={{
                  display: "flex",
                  justifyContent:
                    item.type === "BOT" ? "flex-start" : "flex-end",
                  margin: "10px",
                }}
              >
                <List.Item
                  key={item.message}
                  style={
                    item.type === "BOT" ? botMessageStyle : customerMessageStyle
                  }
                >
                  {item.message}
                </List.Item>
              </div>
            )}
          />
        </div>
        {loading ? (
          <Spin style={{ display: "flex", justifyContent: "center" }} />
        ) : (
          <div>{StepComponents[ChatStepsConfig[currentStep]]}</div>
        )}
        <div ref={messagesEndRef}></div>
        <Modal
          title="Deseja finalizar o agendamento?"
          centered
          visible={modalAppointmentConfirmOpen}
          onOk={handleCreateAppoitment}
          onCancel={() => setModalAppointmentConfirmOpen(false)}
          footer={[
            <Button
              key="back"
              onClick={() => setModalAppointmentConfirmOpen(false)}
            >
              Não
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleCreateAppoitment}
            >
              Sim
            </Button>,
          ]}
        >
          <Descriptions size="small" layout="vertical">
            <Descriptions.Item label="" style={{ textAlign: "left" }}>
              <div>
                Nome: {name}
                <br />
                Telefone/Celular: {number}
                <br />
                Data: <strong>{dateExtend(date.format("YYYY-MM-DD"))}</strong>
                <br />
                Horário:{" "}
                <strong>
                  {minutesToHourFormated(Number(schedule))} -{" "}
                  {minutesToHourFormated(Number(servicesTotalTime + schedule))}
                </strong>
                <br />
                Preço total:
                <strong> {priceToCurrencyString(data.price)}</strong>
                <br />
                Serviços: {data.serviceNames}
              </div>
            </Descriptions.Item>
          </Descriptions>
        </Modal>
        <Modal
          title="Erro no agendamento"
          centered
          visible={modalAppointmentErrorOpen}
          onOk={() => setModalAppointmentErrorOpen(false)}
          onCancel={() => setModalAppointmentErrorOpen(false)}
          footer={[
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => setModalAppointmentErrorOpen(false)}
            >
              Agendar novo horário
            </Button>,
          ]}
        >
          <Descriptions size="small" layout="vertical">
            <Descriptions.Item label="" style={{ textAlign: "left" }}>
              O horário escolhido não está mais disponível! Tente novamente com
              um novo horário, por favor.
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      </Col>
    </Row>
  );
};

export default Chat;
