export const textMonths = {
  1: "Janeiro",
  2: "Fevereiro",
  3: "Março",
  4: "Abril",
  5: "Maio",
  6: "Junho",
  7: "Julho",
  8: "Agosto",
  9: "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro",
};

export const dateFormated = (date) => {
  return date.format("YYYY-MM-DD");
};

export const dateWithoutTimestamp = (date) => {
  if (!date) return "";
  return date.split("T")[0];
};

export const dateExtend = (date) => {
  const daysOfWeek = [
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
    "Domingo",
  ];
  const [year, month, day] = date.split("T")[0].split("-");
  const formattedMonth = month.padStart(2, '0'); 
  const formattedDay = day.padStart(2, '0'); 

  return ` (${daysOfWeek[new Date(date).getDay()]}) - ${formattedDay}/${formattedMonth}/${year}`;
};

export const dateInitial = (date) => {
  const [year, month, day] = date.split("T")[0].split("-");
  const formattedMonth = month.padStart(2, '0');
  const formattedDay = day.padStart(2, '0'); 

  return `${formattedDay}/${formattedMonth}/${year}`;
};


export const minutesToHourString = (minutes) => {
  const hourCalc = Math.trunc(minutes / 60);

  if (!hourCalc) return `${minutes} min`;

  const minutesCalc = minutes % 60;

  if (!minutesCalc) return `${hourCalc}h`;

  const minutesWithZero = addZero(minutes % 60);

  return `${hourCalc}h${minutesWithZero}min`;
};

export const minutesToHourFormated = (minutes) => {
  const hourCalc = Math.trunc(minutes / 60);
  const minutesCalc = minutes % 60;

  const minutesWithZero = addZero(minutesCalc);
  const hoursWithZero = addZero(hourCalc);

  return `${hoursWithZero}:${minutesWithZero}`;
};

function addZero(numero) {
  if (numero <= 9) return "0" + numero;
  else return numero;
}

export const priceToCurrencyString = (price) => {
  if (price === 0) {
    return "Valor a combinar";
  }
  return price.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
};

export function numberFormated(number) {
  return number && number.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3");
}