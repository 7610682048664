import React from "react";
import "./index.less"
import { Button, Descriptions, Result } from "antd";
import { getCustomerAppointment } from "../../utils/authentication";
import {
  minutesToHourFormated,
  priceToCurrencyString,
} from "../../utils/format";
import { useHistory } from "react-router-dom";

const PageSuccess = () => {
  const history = useHistory();
  const appointment = getCustomerAppointment();
  return (
    <Result
      status="success"
      title="Agendamento Realizado"
      subTitle="Seu agendamento foi confirmado."
      extra={
        <>
          <Descriptions
            title={`Agendamento Nº${appointment.id}`}
            size="small"
            layout="vertical"
          >
            <Descriptions.Item label="" style={{ textAlign: "left" }}>
            <div className="container-service">
                <h2 className="resumo">Resumo</h2>
                <p className="resumo">
                  <strong>
                    {appointment.dateExtend},{" "}
                    {minutesToHourFormated(appointment.start_time)} até{" "}
                    {minutesToHourFormated(appointment.end_time)}
                  </strong>{" "}
                </p>
                <div >
                  {appointment.servicesMapped.map(service => (
                    <div className="service">
                      {service.name} 
                      <br /> 
                      <strong>{priceToCurrencyString(service.price)}</strong>
                    
                    </div>
                  ))    
                } 
                </div>
                <div className="line"></div>
                <div className="service">
                  <p>Total</p>
                  <p>
                    <strong>{priceToCurrencyString(appointment.price)}</strong>
                  </p>
                </div>
              </div>
            </Descriptions.Item>
          </Descriptions>
          <Button
            type="primary"
            onClick={() => history.goBack()}
            style={{ width: "100%" }}
          >
            Novo Agendamento
          </Button>
          <div style={{ height: "10px" }} />
          <Button
            style={{ width: "100%" }}
            onClick={() =>
              history.push(`/agendamento/${appointment.hash}/cancelar`)
            }
          >
            Cancelar Agendamento
          </Button>
        </>
      }
    />
  );
};

export default PageSuccess;
