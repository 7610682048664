import { Input, Button } from "antd";

import { InputStyle } from "../styles/inputStyle";
import { numberFormated } from "../../../utils/format";
import { validatePhone } from "../../../utils/validators";
import { StepFooterStyle } from "../styles/stepFooterStyle";

const PhoneStep = ({ number, onChangeNumber, onFinish }) => {
  return (
    <div style={StepFooterStyle}>
      <Input
        type="tel"
        pattern="[0-9]*"
        style={{ ...InputStyle }}
        size="large"
        value={number}
        onChange={(e) => {
          const numbers = e.target.value.replace(/[^0-9]/g, "");
          onChangeNumber(numberFormated(numbers));
        }}
        placeholder="Ex.: (84) 994654749"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onFinish(number);
          }
        }}
        maxLength={15}
      />
      <Button
        type="primary"
        htmlType="submit"
        className="action"
        size="large"
        disabled={number.length < 11 || !validatePhone(number)}
        onClick={() => onFinish(number)}
      >
        Enviar
      </Button>
    </div>
  );
};

export default PhoneStep;
