import { Input, Button } from "antd";

import { InputStyle } from "../styles/inputStyle";
import { StepFooterStyle } from "../styles/stepFooterStyle";

const NameStep = ({ name, onChangeName, onFinish }) => {
  return (
    <div style={StepFooterStyle}>
      <Input
        style={{ ...InputStyle }}
        size="large"
        placeholder="Seu nome"
        value={name}
        onChange={(e) => {
          const result = e.target.value.replace(
            /[^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÒÖÚÇÑ'~˜` ]+$/,
            ""
          );
          onChangeName(result);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onFinish();
          }
        }}
        maxLength={50}
      />
      <Button
        type="primary"
        htmlType="submit"
        className="action"
        size="large"
        disabled={name.length < 3}
        onClick={onFinish}
      >
        Enviar
      </Button>
    </div>
  );
};

export default NameStep;
