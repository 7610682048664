import { Button } from "antd";
import { StepFooterStyle } from "../styles/stepFooterStyle";

const ChoiceActionStep = ({ onClickNewAppointment, onClickMyAppointments }) => {
  return (
    <div style={StepFooterStyle}>
      <Button
        type="primary"
        htmlType="submit"
        className="action"
        size="large"
        onClick={onClickNewAppointment}
      >
        Novo Agendamento
      </Button>
      <Button
        htmlType="submit"
        className="action-secundary"
        size="large"
        onClick={onClickMyAppointments}
      >
        Ver Agendamentos
      </Button>
    </div>
  );
};

export default ChoiceActionStep;
