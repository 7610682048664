import { Button } from "antd";

import { minutesToHourFormated } from "../../../utils/format";
import { StepFooterStyle } from "../styles/stepFooterStyle";

const ScheduleStep = ({
  schedules,
  schedule,
  onChangeSchedule,
  OnClickBack,
  onFinish,
}) => {
  return (
    <>
      <div className="select-list-rows">
        {schedules.map((item) => (
          <div
            className="box-time"
            onClick={() => {
              onChangeSchedule(item);
            }}
            key={item.schedule}
          >
            <div
              className={`info-time ${item === schedule ? "info--active" : ""}`}
            >
              <p className="time-schedule">{minutesToHourFormated(item)} </p>
            </div>
          </div>
        ))}
      </div>

      <div style={StepFooterStyle}>
        <Button
          type="primary"
          htmlType="submit"
          className="action"
          size="large"
          disabled={!schedule}
          onClick={onFinish}
        >
          Finalizar Agendamento
        </Button>
        <Button
          htmlType="submit"
          className="action-secundary"
          size="large"
          onClick={OnClickBack}
        >
          Voltar
        </Button>
      </div>
    </>
  );
};

export default ScheduleStep;
