import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Modal, Descriptions } from "antd";
import moment from "moment";
import "moment/locale/pt-br";

import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

import {
  dateExtend,
  minutesToHourFormated,
  numberFormated,
  priceToCurrencyString,
} from "../../../utils/format";
import { statusLabel } from "../../../utils/constants";
import { isNotExpired } from "../../../utils/validators";
import { StepFooterStyle } from "../styles/stepFooterStyle";

const AppointmentsListStep = ({ appointments, onClickNewAppointment }) => {
  const history = useHistory();
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [modalAppointmentDetailsOpen, setModalAppointmentDetailsOpen] =
    useState(false);

  return (
    <>
      <div className="select-checkbox">
        {appointments
          .filter((item) => item.status !== "canceled")
          .map((item) => (
            <div
              className={`checkbox-item`}
              style={{ display: "flex", flexDirection: "column" }}
              key={item.id}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <p>
                  Nº: <span>{item.id}</span>
                </p>
                <p>
                  🚩 Status: <span>{statusLabel[item.status]}</span>
                </p>
                <p>
                  🗓️ Data:
                  <b> {dateExtend(moment(item.date).format("YYYY-MM-DD"))}</b>
                </p>
                <p>
                  🕒 Horário:{" "}
                  <b>
                    {minutesToHourFormated(item.start_time)}-
                    {minutesToHourFormated(item.end_time)}
                  </b>
                </p>
                <p>
                  💰 Preço: <span>{priceToCurrencyString(item.price)}</span>
                </p>
              </div>
              <div style={{ width: "240px" }}>
                <Button
                  type="info"
                  htmlType="submit"
                  className="login-form-button"
                  size="small"
                  style={{
                    height: `36px`,
                    fontSize: `16px`,
                    width: "100%",
                  }}
                  onClick={() => {
                    setAppointmentDetails(item);
                    setModalAppointmentDetailsOpen(true);
                  }}
                  icon={<SearchOutlined />}
                >
                  Detalhes
                </Button>
                {item.status === "pending" &&
                  isNotExpired(moment(item.date)) && (
                    <Button
                      type="danger"
                      htmlType="submit"
                      className="login-form-button"
                      size="small"
                      style={{
                        height: `36px`,
                        fontSize: `16px`,
                        width: "100%",
                        marginTop: "10px",
                      }}
                      onClick={() =>
                        history.push(`/agendamento/${item.hash}/cancelar`)
                      }
                      icon={<CloseOutlined />}
                    >
                      Cancelar
                    </Button>
                  )}
              </div>
            </div>
          ))}
      </div>

      <div style={StepFooterStyle}>
        <Button
          type="primary"
          htmlType="submit"
          className="action"
          size="large"
          onClick={onClickNewAppointment}
        >
          Novo Agendamento
        </Button>
      </div>

      <Modal
        title="Detalhes do agendamento"
        centered
        visible={modalAppointmentDetailsOpen}
        onCancel={() => setModalAppointmentDetailsOpen(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => setModalAppointmentDetailsOpen(false)}
          >
            Ok
          </Button>,
        ]}
      >
        <Descriptions size="small" layout="vertical">
          <Descriptions.Item label="" style={{ textAlign: "left" }}>
            {appointmentDetails && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <p>Nº: {appointmentDetails.id}</p>

                <p>
                  🚩 Status:{" "}
                  <span>{statusLabel[appointmentDetails.status]}</span>
                </p>
                <p>
                  🗓️ Data:
                  <b>
                    {" "}
                    {dateExtend(
                      moment(appointmentDetails.date).format("YYYY-MM-DD")
                    )}
                  </b>
                </p>
                <p>
                  🕒 Horário:{" "}
                  <b>
                    {minutesToHourFormated(appointmentDetails.start_time)}-
                    {minutesToHourFormated(appointmentDetails.end_time)}
                  </b>
                </p>
                <p>
                  💈 Serviços:{" "}
                  <b>
                    {appointmentDetails.services
                      .map(({ name }) => name)
                      .join(", ")}
                  </b>
                </p>
                <p>
                  💰 Preço:{" "}
                  <span>{priceToCurrencyString(appointmentDetails.price)}</span>
                </p>
                <p>
                  📱 Contato do profissional:{" "}
                  <b>
                    <a
                      id="link-contact"
                      href={`https://wa.me/55${appointmentDetails.user.number}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {numberFormated(appointmentDetails.user.number)}
                    </a>{" "}
                  </b>
                </p>
                <p className="Data-created">
                  🗓️ Criado em:{" "}
                  {moment(appointmentDetails.created_at).format(
                    "DD/MM/YYYY - HH:mm:ss"
                  )}
                </p>
              </div>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  );
};

export default AppointmentsListStep;
